import { Box, Typography } from '@mui/material';
import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Link from 'next/link';

import isEmail from 'validator/lib/isEmail';
import executeGrecaptcha from "../../../utils/executeGrecaptcha";

const ContactForm = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [teleNumber, setTeleNumber] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSubmit = React.useCallback(async () => {
    if (!isEmail(email)) {
      setMessage('Ange en korrekt e-postadress.');
      return;
    }
    try {
      executeGrecaptcha("Contact", async (token: string) => {
        const wl = window.location
        const recaptchaData = await axios.post(
          `${process.env.NEXT_PUBLIC_API_ENDPOINT}v2/contact`,
          {
            captcha: token,
            name,
            email,
            phone: teleNumber,
            url: wl.origin + wl.pathname
          }
        );
        if (recaptchaData && recaptchaData.data.success === true) {
          setMessage('Meddelandet har skickats.');
          window.dataLayer.push({
            event: "submitted_contact_form"
          })
        }
      })
    } catch (error) {
      setMessage('Okänt fel. Vänligen kontakta kundtjänst.');
    }
  }, [name, email, teleNumber]);
  return (
    <Box py={1}>
      {/* <form onSubmit={() => handleSubmit()}> */}
      <Box
        sx={{
          display: 'flex',
          // width: '60%',
          justifyContent: 'center',
          flexDirection: 'column',
          // marginLeft: '10%',
          paddingX: { xs: 1, md: 3 },
        }}
      >
        <TextField
          id="Ditt namn"
          label="Ditt namn"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          id="Din e-post"
          label="Din e-post"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="Ditt telefonnummer"
          label="Ditt telefonnummer"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={teleNumber}
          onChange={(e) => setTeleNumber(e.target.value)}
        />
        {message !== '' && (
          <>
            <Typography
              sx={{
                color:
                  message === 'Meddelandet har skickats.' ? 'green' : 'red',
              }}
            >
              {message}
            </Typography>
          </>
        )}
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ mt: '15px' }}
          type="submit"
          onClick={handleSubmit}
        >
          Kontakta mig
        </Button>
        <Typography sx={{ marginTop: '20px' }}>
          Ta del av vår <Link href="/integritetspolicy">integritetspolicy</Link>
        </Typography>
      </Box>
      {/* </form> */}
    </Box>
  );
};

export default ContactForm;
